/** @format */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.cdnfonts.com/css/helvetica-neue-55");

///////////////
// Brand Color
///////////////
$poly-green: #29562a;
$pigment-green: #26a65b;
$yellow-green: #b2d235;
$peach-yellow: #f9dcad;
$lemon: #0cf2df;
$alice-blue: #dff4fe;

$dark-gray: #2c3e4b;
$gray-3: #808b93;
$gray-2: #d4d8db;
$gray-1: #f8f9fa;

$main-font: "HelveticaNeue", "Roboto", sans-serif;

$error: #f03d3e;
$orange: #f87329;

.text-trans-none {
  text-transform: none !important;
}

.test-banner {
  position: fixed !important;
  z-index: 4 !important;
  width: 76% !important;
  display: flex !important;
  justify-content: flex-end !important;

  h2 {
    background-color: white !important;
    padding: 1rem !important;
  }
}

.alert-info {
  background: #f75a5b !important;
  border-color: #f75a5b !important;
  color: white !important;
}

.bg-danger {
  background-color: $orange !important;
}

.text {
  &-pigment-green {
    color: $pigment-green !important;
  }
  &-poly-green {
    color: $poly-green !important;
  }
  &-yellow-green {
    color: $yellow-green !important;
  }
}

.text-sm {
  font-size: 1.5rem !important;
}

.text-greyy {
  color: $gray-3 !important;
}

.mm-active {
  a {
    background-color: $pigment-green !important;
    transition: all 0.2s;
    color: white !important;
  }
}

.mm-active a {
  background-color: $poly-green !important;
}

.dlab-join-card {
  background-image: linear-gradient(
    to right,
    $yellow-green 0,
    $pigment-green 30%,
    $poly-green 100%
  ) !important;
}

.dlab-join-card.fellow-dashboard {
  background-image: linear-gradient(
    to right,
    $poly-green 0,
    $pigment-green 30%,
    $poly-green 100%
  ) !important;
}

.dlab-cource {
  &.bg-secondary {
    background-color: $pigment-green !important;
  }
}

.btn-primary {
  background-color: $poly-green !important;
  border-color: $poly-green !important;
  transition: all 0.2s;
  color: white;

  &.activeBtn {
    color: #fff !important;
  }

  &:hover {
    opacity: 0.7 !important;
  }
}

.btn-outline {
  background-color: white !important;
  border-color: $poly-green !important;
  transition: all 0.2s;
  color: $poly-green;

  &:hover {
    color: $pigment-green;
    opacity: 0.7 !important;
  }
}

.text-primary {
  color: $poly-green !important;
}

.badge {
  &-new {
    background-color: rgba(212, 216, 219, 0.425) !important;
    color: $dark-gray !important;

    i {
      color: $dark-gray !important;
    }
  }

  &-selected {
    background-color: $poly-green !important;
    color: white !important;

    i {
      color: white !important;
    }
  }

  &-shortlisted {
    background-color: $poly-green !important;
    color: white !important;

    i {
      color: white !important;
    }
  }

  &-approved {
    background-color: $poly-green !important;
    color: white !important;

    i {
      color: white !important;
    }
  }

  &-admitted {
    background-color: #b3d23525 !important;
    color: $yellow-green;

    i {
      color: $yellow-green !important;
    }
  }

  &-enrolled {
    background-color: #b3d23525 !important;
    color: $yellow-green !important;

    i {
      color: $yellow-green !important;
    }
  }

  &-training {
    background-color: #dff4fe !important;
    color: #15455b;

    i {
      color: #15455b !important;
    }
  }

  &-certified {
    background-color: #1DA1F2 !important;
    color: #ffffff;

    i {
      color: #ffffff !important;
    }
  }

  &-incomplete {
    background-color: $error !important;
    color: white;

    i {
      color: white !important;
    }
  }

  &-blacklist {
    background-color: $error !important;
    color: white;

    i {
      color: white !important;
    }
  }

  &-employed {
    background-color: rgba(41, 86, 42, 0.215) !important;
    color: $poly-green !important;

    i {
      color: $poly-green !important;
    }
  }

  &-inactive {
    background-color: rgb(255, 99, 71, ) !important;
    color: #fff !important;

    i {
      color: #fff !important;
    }
  }

  &-incomplete_alc_training {
    background-color: rgba(208, 215, 0, 0.918) !important;
    color: #000 !important;

    i {
      color: #000 !important;
    }
  }

  &-completed_alc_training {
    background-color: rgba(41, 86, 42, 0.215) !important;
    color: $poly-green !important;

    i {
      color: $poly-green !important;
    }
  }

  &-primary {
    background-color: #dff4fe !important;
    color: #15455b;

    i {
      color: #15455b !important;
    }
  }

  &-deeptech {
    border-color: #1DA1F2 !important;
    color: #1DA1F2;

    i {
      color: #1DA1F2 !important;
    }
  }
}

.btn-pigment-green {
  background-color: $pigment-green !important;
  border-color: $pigment-green !important;
  transition: all 0.2s;
  color: white;

  &:hover {
    opacity: 0.7;
    color: white;
  }
}

.btn-pigment-green-outline {
  border-color: $pigment-green !important;
  background-color: transparent !important;
  transition: all 0.2s;

  color: $pigment-green !important;
}

.btn-alice-blue {
  background-color: $alice-blue !important;
  border-color: $alice-blue !important;
  transition: all 0.2s;

  &:hover {
    opacity: 0.7;
  }
}

.btn-alice-blue-outline {
  border-color: $alice-blue !important;
  background-color: transparent !important;
  transition: all 0.2s;

  color: $alice-blue !important;
}

.btn-error {
  background-color: $error !important;
  border-color: $error !important;
  transition: all 0.2s;
  color: white;

  &:hover {
    opacity: 0.7;
    color: white;
  }
}

[data-typography="Helvetica"] {
  font-family: "HelveticaNeue", "Roboto", sans-serif !important;
}

.main-font {
  font-family: "HelveticaNeue", "Roboto", sans-serif !important;
  color: blue !important;
  font-size: 2rem !important;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
span,
ul,
ol,
li,
table,
strong,
div,
section,
main,
th,
tbody,
tr,
thead,
article,
button {
  font-family: "HelveticaNeue", "Roboto", sans-serif !important;
}

.no-border {
  border: 0 !important;
}

// SweetAlert
.swal2-styled {
  &.swal2-confirm {
    background-color: $pigment-green !important;
  }

  &.swal2-cancel {
    background-color: $error !important;
  }
}

.swal2-icon-content {
  .text-danger {
    color: $error !important;
    font-size: 75px !important;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background: #dddada !important;
  opacity: 0.5 !important;
}

.basis-half {
  flex-basis: 50% !important;
}

.mm__logo {
  width: auto;
  height: 6rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: block;
    width: 10rem;
    height: 100%;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
}

.peoeple-login {
  .education-img {
    position: relative;
  }
}

// .sign .education-img {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   width: 100%;
// }
// @media only screen and (max-width: 47.9375rem) {
//   .sign .education-img {
//     position: unset;
//   }
// }

// .photo-content .cover-photo {
//   background: url("../images/3MTT/bG\ 1.jpg") !important;
// }

/** @format */

div[data-lastpass-icon-root="true"] {
  opacity: 0;
}
div[data-lastpass-infield="true"] {
  opacity: 0;
}

.password__container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #777777;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dbdbdb;
  appearance: none;
  border-radius: 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.password__container button {
  position: absolute;
  right: 5%;
  top: 20%;
  transform: translateY(30%);
  outline: none;
  border: 0;
  background-color: transparent;
}
.password__container input {
  background: #fff;
  color: #6e6e6e;
  line-height: 2.4;
  font-size: 1rem;

  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #777777;
  background-clip: padding-box;
  border: 0;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.swal-button {
  border: transparent !important;
}

.swal-button:not([disabled]):hover {
  opacity: 0.7 !important;
}

.swal-button.btn-danger:hover {
  opacity: 0.7;
  background-color: #f87374;
  border-color: #f86b6b;
}

.swal-button.btn-primary:hover,
.swal-button.btn-success:hover {
  opacity: 0.7;
  background-color: #61ce70;
  border-color: #61ce70;
}

.swal2-popup {
  border-radius: 24px !important;
}

.swal2-close {
  top: 6px !important;
}

.swal2-content {
  font-size: 1rem !important;
}

.swal2-title {
  font-size: 1.5rem !important;
}

.swal2-popup {
  width: 23rem !important;
}

.btn-primary:focus,
.btn-success:focus,
.btn-danger:focus {
  box-shadow: none !important;
}

.div-height {
  height: 45px;
}

.div-height .hide-input {
  opacity: 0;
  pointer-events: none;
}

.height-15 {
  height: 15rem;
}

figure.main-logo {
  height: 3rem;
  width: 3rem;
  overflow: hidden;

  @media (max-width: 900px) {
    height: 2rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.brand-logo.jc {
  justify-content: center !important;
  height: 8rem !important;

  @media (max-width: 900px) {
    height: 6rem !important;
  }
}

.ministry-logo {
  display: flex;
  align-items: center;
  justify-content: center;

  figure {
    // height:auto;

    width: 5rem;
    height: 5rem;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.courses__options {
  .form-check {
    font-size: 10px !important;
  }
}

.grid-col {
  &.fellow-profile {
    padding: 1rem !important;
  }
}

.link-hover {
  transition: 0.2s all !important;
  cursor: pointer !important;
  &:hover {
    background-color: $pigment-green !important;
    color: white !important;
  }
}

.shadow-none {
  box-shadow: none !important;
}

.profile .profile-photo {
  margin-top: 0;
}

.profile-info {
  align-items: center !important;
}

.sign .education-img {
  left: 10% !important;
  width: 85% !important;
}

.minvh {
  min-height: 100% !important;
}

.perk-card {
  .card-header {
    height: 120px;
    overflow: hidden;
    justify-content: center;

    figure {
      width: 15rem !important;
      height: 4rem !important;

      img {
        height: 100% !important;
        width: 100% !important;
        object-fit: contain !important;
      }
    }
  }

  

  .card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

figure.card-figure {
    width: 100% !important;
    height: 10rem !important;

    img {
      height: 100% !important;
      width: 100% !important;
      object-fit: cover !important;
    }
  }

.course-link-item {
  &.active-parent {
    a.parent-link {
      background-color: $pigment-green !important;
      color: white !important;

      i {
        color: white !important;
      }

      &.has-arrow::after {
        border-top: 0.3125rem solid white !important;
        border-left: 0.3125rem solid white !important;
      }
    }
  }

  &.mm-active {
    a.parent-link {
      background-color: $pigment-green !important;
      color: white !important;
    }

    a.mm-active.child-link {
      background-color: white !important;
      color: $pigment-green !important;
    }

    a.child-link {
      background-color: white !important;
      color: #737b8b !important;

      &::before {
        background-color: $pigment-green !important;
      }

      &:hover {
        color: $pigment-green !important;
      }
    }
  }
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.bulk-select {
  padding:0 0.5rem !important;
}


.course-modules {
  .accordion-active-header .accordion-button:not(.collapsed) {
    border-color: $pigment-green !important;
    background-color: $pigment-green !important;
    border-color: $pigment-green !important;
    color: white !important;
  }

  h4 {
    margin-bottom: 0 !important;
  }
}

.course-lesson {
  border: 1px solid #becad2;
  padding: 1rem;
  margin-bottom: 1rem;

  &.cta-btns {
    border: none !important;
    justify-content: flex-end !important;
    margin-bottom: 0;

    i {
      margin-right: 1rem;
    }
  }

  h5,
  p {
    margin-bottom: 0 !important;
  }
}

.mr-2 {
  margin-right: 0.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.forum-search {
  width: 30% !important;

  @media (max-width: 760px) {
    width: 100% !important;
  }
}

.chat-container {
  height: 85vh !important;
  overflow: hidden !important;

  @media (max-width: 750px) {
    height: 80% !important;
  }

  .card {
    .chat-box-area {
      height: calc(100vh - 20rem) !important;

      @media (max-width: 750px) {
        height: calc(100vh - 30rem) !important;
      }
    }
  }
}

.chat-card {
  position: fixed !important;
  width: 70% !important;
  height: calc(100% - 17vh) !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  scrollbar-color: transparent !important;

  @media (max-width: 750px) {
    height: calc(100% - 20vh) !important;
  }

  &::-webkit-scrollbar {
    display: none !important;
  }

  @media (max-width: 750px) {
    width: 93% !important;
  }
}

.chat-scroll {
  overflow-y: scroll !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  scrollbar-color: transparent !important;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

.cscrollable {
  overflow-y: scroll !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  scrollbar-color: transparent !important;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

.search--box {
  width: 40% !important;
  padding-right: 10px !important;
}

.modal-header .close {
  top: -0.5rem !important;
}

.course-dedails-bx {
  box-shadow: none !important;

  .card-header {
    padding: 0 !important;
  }

  .card-body {
    padding: 0 !important;
  }
}

.height-250 {
  height: 250px !important;
}

.disabled {
  pointer-events: none !important;
  opacity: 0.65 !important;
}

.react-datepicker-wrapper {
  widtH:100%;
}